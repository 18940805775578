import React from "react";

const Spacer = ({height}) => {
  return (
    <div className="spacer" style={{ height: `${height}px` }}>
      {" "}
    </div>
  );
};

export default Spacer;
